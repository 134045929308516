export default {
  error: 'خطأ أثناء تنفيذ العملية',
  errorDetails: 'حدث خطأ أثناء تنفيذ العملية, إذا كنت تعتقد أنه يوجد مشكلة تقنية, من فضلك قم بمخاطبة إدارة الموقع',

  success: 'تم تتفيذ العملية',
  branchAdded: 'تم إضافة فرع جديد',
  branchUpdated: 'تم تحديث بيانات الفرع',

  userInfoUpdated: 'تم حديث البيانات العامة',
  socialMediaUpdated: 'تم تحديث الموقع الإلكتروني وروابط التواصل الإجتماعي',

  passwordUpdated: 'تم حديث كلمة المرور ',

  adCreated: 'تم إضافة الإعلان',
  adUpdated: 'تم تعديل الإعلان',
  adDeleted: 'تم حذف الإعلان',
}
