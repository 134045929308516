<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- logo -->
        <b-link
          :to="{name: 'login'}"
          class="brand-logo"
        >
          <vuexy-logo />
        </b-link>

        <b-card-title class="mt-4 text-center">
          إعادة ضبط كلمةالمرور 🔒
        </b-card-title>

        <div
          v-if="errorMessage !== null"
          class="alert alert-danger p-2"
        >
          <p>
            {{ errorMessage }}
          </p>
        </div>

        <div
          v-if="successMessage !== null"
          class="alert alert-success p-2"
        >
          <p>
            {{ successMessage }}
          </p>
        </div>

        <!-- form -->
        <validation-observer ref="resetPasswordForm">
          <b-form
            class="py-1"
            @submit.prevent="validateAndReset()"
          >
            <b-row>
              <!-- new password -->
              <b-col md="12">
                <b-form-group
                  label-for="account-new-password"
                  label="كلمة المرور"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="password"
                    vid="password"
                    rules="required|min:8"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="account-new-password"
                        v-model="password"
                        :type="passwordFieldTypeNew"
                        name="password"
                        placeholder=""
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIconNew"
                          class="cursor-pointer"
                          @click="togglePasswordNew"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--/ new password -->

              <!-- retype password -->
              <b-col md="12">
                <b-form-group
                  label-for="account-retype-new-password"
                  label="إعادة كلمة المرور"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="confirmed"
                    vid="confirmed"
                    rules="required|confirmed:password"
                  >
                    <b-input-group class="input-group-merge">

                      <b-form-input
                        id="account-retype-new-password"
                        v-model="confirmation"
                        :type="passwordFieldTypeRetype"
                        name="confirmed"
                        placeholder=""
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIconRetype"
                          class="cursor-pointer"
                          @click="togglePasswordRetype"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--/ retype password -->

              <!-- buttons -->
              <b-col cols="12">
                <ButtonSpinner
                  type="submit"
                  text="إعادة ضبط كلمة المرور"
                  :show-spinner="formSubmitted"
                  class="btn-block"
                />
              </b-col>
              <!--/ buttons -->
            </b-row>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> العودة لتسجيل الدخول
          </b-link>
        </p>

      </b-card>
    <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BLink, BCardTitle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import VuexyLogo from '@core/layouts/components/Logo.vue'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import usersApi from '@/axios/api/user'
import messages from '@/libs/messages/toast'

import ButtonSpinner from '@/views/components/ButtonSpinner.vue'

export default {
  components: {
    VuexyLogo,
    BLink,
    BCardTitle,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    ButtonSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      formSubmitted: false,
      password: '',
      confirmation: '',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',

      form: {
        token: null,
        email: null,
      },

      errorMessage: null,
      successMessage: null,
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  mounted() {
    const urlParams = new URLSearchParams(window.location.search)
    this.form.token = urlParams.get('token')
    this.form.email = urlParams.get('email')
  },

  methods: {
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },

    validateAndReset() {
      if (this.form.token == null || this.form.email == null) {
        this.errorMessage = 'الرابط غير صحيح او قد انتهي!'
        return
      }

      this.$refs.resetPasswordForm.validate().then(success => {
        if (success) {
          this.resetPassword()
        }
      })
    },

    async resetPassword() {
      this.formSubmitted = true

      const form = {
        password: this.password,
        confirmPassword: this.confirmation,
        token: this.form.token,
        email: this.form.email,
      }

      try {
        await usersApi.resetPassword(form)

        this.successMessage = messages.passwordUpdated
      } catch (error) {
        console.log(error)
        this.errorMessage = messages.errorDetails
      }

      this.formSubmitted = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.brand-logo img {
  max-width: 200px !important;
}
</style>
